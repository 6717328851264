import React from "react";
import "./Home.scss";
import Hero from "../../Section/Hero/Hero";
import About from "../../Section/About/About";
import Promo from "../../Section/Promo/Promo";
import Lokasi from "../../Section/Lokasi/Lokasi";
import Fasilitas from "../../Section/Fasilitas/Fasilitas";
import Product from "../../Section/Product/Product";
const Home = () => {
  return (
    <div className="home">
      <Hero />
      <About />
      <Promo />
      <Product />
      <Fasilitas />
      <Lokasi />
    </div>
  );
};

export default Home;
