import React from "react";
import "./Fasilitas.scss";
import clubhouse from "../../Media/Fasilitas/facility.webp";
import joggingtrack from "../../Media/Fasilitas/facility 2.webp";
import civiccenter from "../../Media/Fasilitas/facility 3.webp";
import breeze from "../../Media/Fasilitas/facility 4.webp";
import qbig from "../../Media/Fasilitas/facility 5.webp";
import eka from "../../Media/Fasilitas/facility 6.webp";
import shop from "../../Media/Fasilitas/facility 7.webp";
import hotel from "../../Media/Fasilitas/facility 8.webp";
import hospital from "../../Media/Fasilitas/facility 9.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Fasilitas = () => {
  const slides = [
    { src: clubhouse, des: "Interconnected Pedestrian" },
    { src: joggingtrack, des: "Outdoor Gym" },
    { src: civiccenter, des: "Club House" },
    { src: breeze, des: "Kids Playground" },
    { src: qbig, des: "Commercial Space" },
    { src: eka, des: "Pet Park" },
    { src: shop, des: "Apps & Web" },
    { src: hotel, des: "Feeding Pond" },
    { src: hospital, des: "CBD Area" },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="surroundingcontainer" id="fasilitas">
      <div className="title">PREMIUM FACILITES</div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <img className="imgcontent" src={slide.src} alt="Surrounding" />
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <img className="imgcontent" src={slide.src} alt="Surrounding" />
              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Fasilitas;
