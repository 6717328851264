import React from "react";
import "./Promo.scss";
import promo from "../../Media/flyer.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { ReactComponent as Check } from "../../Media/check.svg";
const item = ["Free AJB", "Free balik nama", "Free CCTV", "Free smoke alarm"];
const item2 = ["Free SOS", "Free smart foor lock", "Free kanopi"];

const waOpen = () => {
  window.open(
    "https://api.whatsapp.com/send/?phone=6282116893004&text=Halo%20Rika,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20(Promo%20keseluruhan)%20The%20Grand%20Kenjeran%20Surabaya%20https://thegrandkenjeran.id/&type=phone_number&app_absent=0",
    "_blank"
  );
};
const Promo = () => {
  return (
    <div className="newcont" id="promo">
      <div className="center">
        <div className="newpromo">
          <div className="promoimg">
            <img alt="promosumareconbandung" src={promo} className="img" />
          </div>
          <div className="promocontent">
            <div className="title">PROMO KESELURUHAN THE GRAND KENJERAN</div>
            <div className="point">
              <ul>
                {item.map((card, index) => (
                  <li key={index}>
                    <Check />
                    &nbsp;
                    {card}
                  </li>
                ))}
                {item2.map((card, index) => (
                  <li key={index}>
                    <Check />
                    &nbsp;
                    {card}
                  </li>
                ))}
              </ul>
            </div>
            <div className="tc">*T&C apply</div>
            <button onClick={waOpen} className="wabutton">
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp;Hubungi Kami
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
