import React from "react";
import "./About.scss";
import about from "../../Media/about.png";

const waOpen = () => {
  window.open(
    "https://api.whatsapp.com/send/?phone=6282116893004&text=Halo%20Rika,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20The%20Grand%20Kenjeran%20Surabaya%20https://thegrandkenjeran.id/&type=phone_number&app_absent=0",
    "_blank"
  );
};
const About = () => {
  return (
    <div className="aboutcont" id="about">
      <div className="center">
        <div className="aboutimg">
          <img className="img" src={about} alt="kanjeran" />
        </div>
        <div className="abouttile">About Us</div>
        <div className="aboutcontent">
          <div className="title">THE GRAND KENJERAN</div>
          <div className="subtitle">
            <div className="text">
              Mega proyek yang mengintegrasikan perumahan, apartement, pusat
              komersial, perkantoran dan berbagai fasilitas lainnya.Dikembangkan
              di tengah lingkungan yang terdesain dengan baik dan asri, terpadu
              dengan alam dan memperhatikan kenyamanan penghuni. Pemanfaatan
              teknologi juga menjadi bagian dari inovasi untuk kian memberi
              kemudahan bermukim.
            </div>
            <button onClick={waOpen} className="wabutton">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
