import "./Product.scss";
import CardProdukW from "../../Component/Card/CardProdukW";
import CardProdukD from "../../Component/Card/CardProdukD";
import cardProduk from "./index.json";
import {
  pprt,
  pevod,
  pwisteria,
  palder,
  psierra,
  pcastella,
  pcorsa,
  pcayman,
  akira,
  asora,
  asora2,
  mmaderia,
  mmarjoria,
  mmaldrian,
  mcmadderia,
  mcmarjoria,
} from "../../Media/Product/imagesMap";
const imageMap = {
  pprt,
  pevod,
  pwisteria,
  palder,
  psierra,
  pcastella,
  pcorsa,
  pcayman,
  akira,
  asora,
  asora2,
  mmaderia,
  mmarjoria,
  mmaldrian,
  mcmadderia,
  mcmarjoria,
};

const Product = () => {
  return (
    <section className="section-product" id="rumah">
      <div id="pinewood">
        <div className="container">
          <div className="label">Our Project</div>
          <h2 className="title">PINEWOOD</h2>
          <div className="container-card">
            {cardProduk.cardsPinewood.map((data, index) => (
              <CardProdukW
                key={index}
                carousel={imageMap[data.imagesKey]}
                cardID={data.cardID}
                title={data.title}
                downPaymentType={data.downPaymentType}
                price={data.price}
                details={data.details}
                whatsappLink={data.whatsappLink}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="akeno">
        <div className="container">
          <div className="label">Our Project</div>
          <h2 className="title">AKENO</h2>
          <div className="container-card">
            {" "}
            {cardProduk.cardsAkeno.map((data, index) => (
              <CardProdukD
                key={index}
                carousel={imageMap[data.imagesKey]}
                cardID={data.cardID}
                title={data.title}
                downPaymentType={data.downPaymentType}
                price={data.price}
                details={data.details}
                whatsappLink={data.whatsappLink}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="mezzanine">
        <div className="container">
          <div className="label">Our Project</div>
          <h2 className="title">MEZZANINE</h2>
          <div className="container-card">
            {cardProduk.cardsMezzanine.map((data, index) => (
              <CardProdukW
                key={index}
                carousel={imageMap[data.imagesKey]}
                cardID={data.cardID}
                title={data.title}
                downPaymentType={data.downPaymentType}
                price={data.price}
                details={data.details}
                whatsappLink={data.whatsappLink}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="ruko-commercial">
        <div className="container">
          <div className="label">Our Project</div>
          <h2 className="title">Ruko Commercial</h2>
          <div className="container-card">
            {cardProduk.cardsRuko.map((data, index) => (
              <CardProdukD
                key={index}
                carousel={imageMap[data.imagesKey]}
                cardID={data.cardID}
                title={data.title}
                downPaymentType={data.downPaymentType}
                price={data.price}
                details={data.details}
                whatsappLink={data.whatsappLink}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Product;
