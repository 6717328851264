import portia from "./Pinewood/portia.webp";
import evodia from "./Pinewood/evodia.webp";
import wisteria from "./Pinewood/wisteria.webp";
import alder from "./Pinewood/alder.webp";
import sierra from "./Pinewood/sierra.webp";
import castella from "./Pinewood/castella.webp";
import corsa from "./Pinewood/corsa.webp";
import cayman from "./Pinewood/cayman.webp";
import kira from "./Akeno/kira.webp";
import sora from "./Akeno/sora.webp";
import madderia from "./Mezzanine/Madderia.webp";
import marjoria from "./Mezzanine/Marjoria.webp";
import maldrian from "./Mezzanine/Maldarian.webp";
import cmadderia from "./Mezzanine/Capital-Madderia.webp";
import cmarjoria from "./Mezzanine/Capital-Marjoria.webp";
const pprt = [portia, portia];
const pevod = [evodia, evodia];
const pwisteria = [wisteria, wisteria];
const palder = [alder, alder];
const psierra = [sierra, sierra];
const pcastella = [castella, castella];
const pcorsa = [corsa, corsa];
const pcayman = [cayman, cayman];
const akira = [kira, kira];
const asora = [sora, sora];
const asora2 = [sora, sora];
const mmaderia = [madderia, madderia];
const mmarjoria = [marjoria, marjoria];
const mmaldrian = [maldrian, maldrian];
const mcmadderia = [cmadderia, cmadderia];
const mcmarjoria = [cmarjoria, cmarjoria];

export {
  pprt,
  pevod,
  pwisteria,
  palder,
  psierra,
  pcastella,
  pcorsa,
  pcayman,
  akira,
  asora,
  asora2,
  mmaderia,
  mmarjoria,
  mmaldrian,
  mcmadderia,
  mcmarjoria,
};
