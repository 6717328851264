import "./Footer.scss";
import logo from "../../Media/logo.svg";
import { HashLink, NavHashLink } from "react-router-hash-link";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer id="footer" className="section-footer">
      <div className="container-footer">
        <div className="footer-top">
          <div className="footer-logo">
            <div>
              <HashLink smooth to="/">
                <img src={logo} alt="logo The Grand Kenjeran" />
              </HashLink>
            </div>
            <div>
              <h3 className="footer-logo-title">Marketing Gallery</h3>
              <div className="footer-logo-text">The Grand Kenjeran</div>
              <div className="footer-logo-text">+6282116893004</div>
              <a href="/privacy-policy" className="footer-logo-text">
                Privacy Policy
              </a>
            </div>
          </div>
          <div className="footer-sitemap">
            <div className="footer-site-link">
              <div className="footer-menu">
                Peta Situs
                <li className="nav-item">
                  <NavHashLink smooth to="/#about" className="nav-links">
                    About us
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#promo" className="nav-links">
                    Promo
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#fasilitas" className="nav-links">
                    Facilities
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#lokasi" className="nav-links">
                    Lokasi
                  </NavHashLink>
                </li>
                <li className="nav-item" onClick={scrollToTop}>
                  <a href="/" className="nav-links">
                    Back to Top
                  </a>
                </li>
              </div>
              <div className="footer-menu">
                Proyek
                <li className="nav-item">
                  <NavHashLink smooth to="/#rumah" className="nav-links">
                    Residentials
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink
                    smooth
                    to="/#ruko-commercial"
                    className="nav-links"
                  >
                    Commercial
                  </NavHashLink>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-rights">
          © 2024 The Grand Kenjeran . All Rights Reserved.
          <div className="footer-powered">Powered by : Linktown</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
