import React from "react";
import "./Hero.scss";
import Slider from "react-slick";
import hero from "../../Media/hero";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
const settings = {
  dots: hero.length > 1,
  arrows: hero.length > 1,
  infinite: hero.length > 1,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const waOpen = () => {
  window.open(
    "https://api.whatsapp.com/send/?phone=6282116893004&text=Halo%20Rika,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20The%20Grand%20Kenjeran%20Surabaya%20https://thegrandkenjeran.id/&type=phone_number&app_absent=0",
    "_blank"
  );
};
const Hero = () => {
  return (
    <div className="herocont">
      <div className="herotitle">
        <div className="title">WELCOME TO</div>
        <div className="subtitle">THE GRAND KENJERAN</div>
        <button onClick={waOpen} className="btn-navbar">
          <FontAwesomeIcon icon={faWhatsapp} /> Contact Us
        </button>
      </div>
      <div className="heroslider">
        <Slider {...settings}>
          {hero.map((image, index) => (
            <img
              key={index}
              className="image"
              src={image}
              alt={`Launching ${index + 1}`}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Hero;
