import "./Lokasi.scss";
import map from "../../Media/map.png";

const Lokasi = () => {
  return (
    <section className="section-lokasi" id="lokasi">
      <h2 className="lokasi-title">LOKASI</h2>

      <div className="lokasi-img">
        <img src={map} alt="map" />
      </div>
    </section>
  );
};

export default Lokasi;
