import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Home from "./Pages/Home/Home";
import Navbar from "./Section/Navbar/Navbar";
import Footer from "./Section/Footer/Footer";
import Privacy from "./Pages/Privacy/Privacy";
function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=6282116893004&text=Halo%20Rika,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20The%20Grand%20Kenjeran%20Surabaya%20https://thegrandkenjeran.id/&type=phone_number&app_absent=0"
        >
          <FloatingWhatsApp />
        </a>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
